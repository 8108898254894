import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { PageContext } from '../types/pageContext';
import { IContentfulEntry } from '../types/contentfulContentTypes';
import ComponentInternalType from '../components/contentful/ComponentInternalType';

export const query = graphql`
  query SupportPageTemplate($id: String!) {
    contentfulPageSupport(id: { eq: $id }) {
      ...PageSupportLegalFragment
    }
    contentfulGlobalOptions {
      ...GlobalFragment
    }
    contentfulComponentSiteHeader {
      logo {
        ...AssetFragment
      }
      userNavigation {
        ...NavigationFragment
      }
      mainNavigation {
        ...NavigationFragment
      }
      searchCta {
        ...LinkFragment
      }
    }
    contentfulComponentSiteFooter {
      exclusiveToRetailer {
        ...ContentFlexibleFragment
      }
      legalCopy {
        ...ContentFlexibleFragment
      }
      logo {
        ...AssetFragment
      }
      menus {
        ...NavigationFragment
      }
      signUpForm {
        ...FormFragment
      }
      socials {
        ...NavigationFragment
      }
    }
  }
`;

const SupportPageTemplate: React.FC<
  PageProps<Queries.SupportPageTemplateQuery, PageContext>
> = (props) => {
  const { data } = props;
  const { contentfulPageSupport } = data;
  return (
    <div className="container">
      <h2 className="mt-4 mb-xxs text-grey-3 font-book uppercase">
        {contentfulPageSupport?.pageTitle}
      </h2>
      <hr></hr>
      <div>
        {contentfulPageSupport?.sections &&
          contentfulPageSupport.sections.map((section, index: number) => (
            <ComponentInternalType
              key={index}
              component={section as IContentfulEntry}
            />
          ))}
      </div>
    </div>
  );
};

export default SupportPageTemplate;
